<template>
    <div id="init-viewer-wrapper">
    </div>
</template>
  
<script>
export default {
    name: 'FloodAnalyse',
    components: {},
    mounted() {
        this.initViewer()
    },
    methods: {
        async initViewer() {
            Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzM2RjYjJlOC01ZTQwLTQwODYtOTEwMy02M2U4OGEzYjQyNGUiLCJpZCI6MjI5NjUsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1ODI0NTYwMzF9.VG2_T9ry8EnBWAh4msJ3s6m2jW_5hgAZQvfEQDh-WQs"
            window.viewer = new window.Cesium.Viewer("init-viewer-wrapper", {
                terrainProvider: new window.Cesium.CesiumTerrainProvider({
                    url: 'https://tiles.geovis.online/base/v1/terrain?token=da4b9d3bc7c731cb3e7a6e327604817c4f372c293ea2e82278b8e05e5834a3a9',
                    requestWaterMask: true,
                    requestVertexNormals: true
                }),
                imageryProvider: new window.Cesium.ArcGisMapServerImageryProvider({
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer'
                }),
                sceneMode: window.Cesium.SceneMode.SCENE3D,
                vrButton: false,
                animation: false,
                baseLayerPicker: false,
                geocoder: false,
                timeline: false,
                fullscreenButton: false,
                homeButton: false,
                creditContainer: document.createElement('div'),
                infoBox: true,
                navigationHelpButton: false,
                sceneModePicker: false,
                scene3DOnly: true,
                skyBox: new window.Cesium.SkyBox({
                    sources: {
                        positiveX: require('@/assets/images/tycho2t3_80_px.jpg'),
                        negativeX: require('@/assets/images/tycho2t3_80_mx.jpg'),
                        positiveY: require('@/assets/images/tycho2t3_80_py.jpg'),
                        negativeY: require('@/assets/images/tycho2t3_80_my.jpg'),
                        positiveZ: require('@/assets/images/tycho2t3_80_pz.jpg'),
                        negativeZ: require('@/assets/images/tycho2t3_80_mz.jpg'),
                    }
                })
            });

            //淹没分析示例先开启地形深度检测
            window.viewer.scene.globe.depthTestAgainstTerrain = true;
           
            //绍兴坐标
            /*
            120.58 30.01 138 147.9 50.15 27.4
            120.58,36.34,8000,
            120.56,50.15,8000,
            147.90,36.34,8000,
            147.90,50.15,8000,

            120.58,36.34,8000,147.90,50.15,8000,147.90,36.34,8000,120.56,50.15,8000

            116.64,36.34,8000,116.66,36.34,8000,116.66,36.32,8000,116.64,36.32,8000,116.64,36.34,8000

            let waterMinElevation=180,waterMaxElevation=400
            */
            // let waterMinElevation=180,waterMaxElevation=300,positions=window.Cesium.Cartesian3.fromDegreesArrayHeights([
            // 116.64,36.34,8000,116.66,36.34,8000,116.66,36.32,8000,116.64,36.32,8000,116.64,36.34,8000
            // ])
            // let entity=window.viewer.entities.add({
            //     polygon:{
            //         hierarchy:new window.Cesium.PolygonHierarchy(positions),
            //         extrudedHeight:new window.Cesium.CallbackProperty(()=>{
            //             if(waterMinElevation<waterMaxElevation){
            //                 waterMinElevation+=0.001
            //             }
            //             return waterMinElevation
            //         }),
            //         material:window.Cesium.Color.fromCssColorString('#3D81A5').withAlpha(0.7)
            //     }
            // })

            //水淹效果
            let waterMinElevation=0,waterMaxElevation=300,positions=window.Cesium.Cartesian3.fromDegreesArrayHeights([
            118.546278,31.522834,waterMaxElevation,
118.538684,31.526019,waterMaxElevation,
118.528921,31.530972,waterMaxElevation,
118.521622,31.532453,waterMaxElevation,
118.516005,31.533083,waterMaxElevation,
118.510902,31.537144,waterMaxElevation,
118.506971,31.541057,waterMaxElevation,
118.503272,31.545872,waterMaxElevation,
118.502962,31.548002,waterMaxElevation,
118.513609,31.550738,waterMaxElevation,
118.521512,31.556205,waterMaxElevation,
118.526021,31.554709,waterMaxElevation,
118.527308,31.551381,waterMaxElevation,
118.530696,31.548695,waterMaxElevation,
118.537796,31.543684,waterMaxElevation,
118.549039,31.541701,waterMaxElevation,
118.548777,31.534778,waterMaxElevation,
118.546907,31.525336,waterMaxElevation,
            ])
            window.viewer.entities.add({
                polygon:{
                    hierarchy:new window.Cesium.PolygonHierarchy(positions),
                    extrudedHeight:new window.Cesium.CallbackProperty(()=>{
                        if(waterMinElevation<waterMaxElevation){
                            waterMinElevation+=0.005
                        }
                        return waterMinElevation
                    }),
                    material:window.Cesium.Color.fromCssColorString('#3D81A5').withAlpha(0.7)
                }
            })


            // window.viewer.entities.add({
            //     polygon:{
            //         hierarchy:new window.Cesium.PolygonHierarchy(positions),
            //         material:window.Cesium.Color.WHITE.withAlpha(0.3)
            //     },
            //     polyline:{
            //         positions:positions,
            //         width:4,
            //         clampToGround:true
            //     }
            // })

            let viewer = window.viewer
            let tileset = new Cesium.Cesium3DTileset({
                    url: "https://almtiles.mascj.com:433/AnHuiSheng/MaAnShanShi/DangTuXian/ZhiHuiCunWuXiangMu/GuShuZhen/baizhucun/20221227_3DTiles/tileset.json"
                })
                viewer.scene.primitives.add(tileset)
                viewer.zoomTo(tileset)

            //范围线展示
            // window.viewer.entities.add({
            //     polygon:{
            //         hierarchy:new window.Cesium.PolygonHierarchy(positions),
            //         material:window.Cesium.Color.WHITE.withAlpha(0.3)
            //     },
            //     polyline:{
            //         positions:positions,
            //         width:4,
            //         clampToGround:true
            //     }
            // })
            
            // window.viewer.flyTo(entity)
            window.viewer.zoomTo(tileset)

            //去点工具
            let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function(event){
                let ray = viewer.camera.getPickRay(event.position);
                let cartesian = viewer.scene.globe.pick(ray, viewer.scene);
                let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                let lng = Cesium.Math.toDegrees(cartographic.longitude); // 经度
                let lat = Cesium.Math.toDegrees(cartographic.latitude); // 纬度
                //let alt = cartographic.height; // 高度
                // let coordinate = {
                //     longitude: Number(lng.toFixed(6)),
                //     latitude: Number(lat.toFixed(6)),
                //     altitude: Number(alt.toFixed(2))
                // };
                let coordinate =Number(lng.toFixed(6))+","+Number(lat.toFixed(6));
                console.log(coordinate);
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);


        }
    }
}
</script>
  
<style>
#init-viewer-wrapper {
    width: 100%;
    height: 100%;
}
</style>
  