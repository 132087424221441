<template>
  <div id="app">
    <Example />
  </div>
</template>

<script>
import Example from './views/FloodAnalyse.vue'//修改引用路径预览不同示例组件
export default {
  name: 'App',
  components: { Example },
  mounted() {
  }
}
</script>

<style></style>
